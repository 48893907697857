<template>
  <div class="maxHeight">
    <div class="header flex ">
      <div class="title flex">
        <img src="../../assets/img/pay.png" alt="" width="28">
        <h2>支付管理</h2>
      </div>
      <el-button size="small" @click="payDiaBtn">支付历史</el-button>
    </div>
    <div class="flex height">
      <div class="commPadding left">
        <div class="flex list">
          <img src="../../assets/img/pay_a.png" alt="" width="32">
          <div class="text">
            <p>现金余额 (元)</p>
            <strong class="number" v-if="!companyInfo.balance">0.00</strong>
            <strong class="number" v-else>{{ companyInfo.balance | filterMoney }}</strong>
          </div>
        </div>
        <div class="flex list">
          <img src="../../assets/img/pay_a.png" alt="" width="32">
          <div class="text">
            <p>赠送金额 (元)</p>
            <strong class="number">
              <template v-if="!companyInfo.presentBalance">0.00</template>
              <template v-else>{{ companyInfo.presentBalance | filterMoney }}</template>
              /
              <template v-if="!companyInfo.presentBalanceTotal">0.00</template>
              <template v-else>{{ companyInfo.presentBalanceTotal | filterMoney }}</template>
            </strong>
          </div>
        </div>
        <div class="flex list">
          <img src="../../assets/img/pay_b.png" alt="" width="32">
          <div class="text">
            <p>使用中笼舍 (个)</p>
            <strong class="number">{{ companyInfo.useCount }}</strong>
          </div>
        </div>
        <div class="flex list">
          <img src="../../assets/img/pay_b.png" alt="" width="32">
          <div class="text">
            <p>已使用笼舍 (个)</p>
            <strong class="number">{{ companyInfo.unUseCount }}</strong>
          </div>
        </div>
        <el-button class="payBtn" @click="topUp">充值金额</el-button>
      </div>
      <div class="commPadding subjectTab">
        <div class="flex tableHead">
          <strong>课题笼舍使用情况</strong>
          <el-input clearable v-model="search" @change="skipPage(1)" placeholder="请输入课题编号"
                    suffix-icon="el-icon-search"></el-input>
        </div>
        <el-table
            border
            :data="tableData"
            class="record-table"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            height="calc(100vh - 312px)">
          <el-table-column prop="subNo" label="课题编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column  label="课题类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :class="scope.row.isLabAni == 0 ? 'experiment' : 'production '">
                {{ scope.row.isLabAni == 0 ? '实验课题' : '生产课题 ' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="subName" label="课题名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="manager" label="负责人" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="课题状态" width="100">
            <template slot-scope="scope">
              <el-button size="small" class="experimentCo" v-if="scope.row.subStatus==40">已结束</el-button>
              <el-button size="small" class="approval" v-else>进行中</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="activeCage" sortable label="使用中笼舍(个)" width="140">
          </el-table-column>
          <el-table-column prop="allCage" sortable label="共使用笼舍(个)" width="140">
          </el-table-column>
          <el-table-column prop="fee" sortable label="共计费用(元)" width="160" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.fee | filterMoney }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openDetail(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex pagination ">
          <el-pagination
              prev-text="<"
              next-text=">"
              @size-change="handleSizeChange"
              @current-change="skipPage"
              :current-page="page.current"
              :page-size="page.size"
              layout="prev, pager, next"
              :total="page.total">
          </el-pagination>
          <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
        </div>
      </div>
    </div>
    <!--    笼舍使用详情-->
    <el-drawer
        size="600px" class="drawerCommon cageInfo"
        :visible.sync="drawer"
        title="笼舍使用详情">
      <h3 class="title">课题信息</h3>
      <div class="info">
        <p>
          <span>课题编号：{{ cageDetail.subNo }}</span>
          <span>课题类型：<label class="type">  {{ cageDetail.subType == 0 ? '实验课题' : '生产课题 ' }}</label></span>
        </p>
        <p>课题名称：{{ cageDetail.subName }}</p>
        <p>负责人：{{ cageDetail.manager }}</p>
        <p>课题状态：<span class="state">{{ cageDetail.subStatus == 40 ? '已完成' : '进行中' }}</span></p>
      </div>
      <h3 class="title">使用中笼舍</h3>
      <div class="info">
        <p v-for="(obj ,index ) in  cageDetail.activeCages">
          <span>笼舍编号：{{ obj.cageNo }}</span>
          <span>笼架编号：{{ obj.frameNo }}</span>
          <span>使用时长：{{ obj.duration }}天</span>
        </p>
        <p>
          <span>共计使用笼舍：{{ cageDetail.activeCages ? cageDetail.activeCages.length : '0' }}</span>
          <span>共计使用时长：{{ activeCageSum }}天</span>
        </p>
      </div>
      <h3 class="title">使用完成笼舍</h3>
      <div class="info">
        <p v-for="(obj ,index ) in  cageDetail.endCages">
          <span>笼舍编号：{{ obj.cageNo }}</span>
          <span>笼架编号：{{ obj.frameNo }}</span>
          <span>使用时长：{{ obj.duration }}天</span>
        </p>
        <p>
          <span>共计使用笼舍：{{ cageDetail.endCages ? cageDetail.endCages.length : '0' }}</span>
          <span>共计使用时长：{{ endCageNum }}天</span>
        </p>
      </div>
    </el-drawer>
    <!--    充值金额-->
    <el-dialog :show-close="true" :close-on-click-modal="false"
               :close-on-press-escape="false"
               title="充值金额"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialogFormVisible"
               width="450px"
    >
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="rules"
               label-width="90px">
        <el-form-item label="支付金额" prop="orderFee">
          <el-input v-model="formAction.orderFee" placeholder="请输入充值金额" min="0.01" type="number">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formAction.remark" type="textarea" rows="4" placeholder="请输入备注内容"></el-input>
        </el-form-item>
        <el-form-item label="支付金额">
          <el-radio-group v-model="formAction.payType" class="payType" :disabled="disabled" @change="changeType">
            <el-radio :label="'wxchat'"><img src="../../assets/img/wx.png" alt="" width="20">微信支付</el-radio>
            <el-radio :label="'bank'"><img src="../../assets/img/bank.png" alt="" width="20">银行转账</el-radio>
          </el-radio-group>
          <div v-show="boxShow" class="qrcode">
            <div class="codeImg">
              <img :src="qrcode" width="100" alt="" height="100"/>
            </div>
            <p>扫描二维码支付</p>
          </div>
        </el-form-item>
        <template v-if="formAction.payType=='bank'">
          <el-form-item class="companyInfo" label="公司名称">亦笙信息技术（深圳）有限公司</el-form-item>
          <el-form-item class="companyInfo" label="收款账户">8110301011700579875</el-form-item>
          <el-form-item class="companyInfo" label="开户银行">中信银行股份有限公司深圳景田支行</el-form-item>
          <el-form-item label="转账凭证">
            <el-upload
                class="upload-demo"
                ref="upload"
                :action="upLoadUrl"
                :headers="headers"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                :file-list="fileList"
                :before-upload="beforeAvatarUpload"
                accept=".jpg,.jpeg,.png">
              <el-button size="small" type="primary" class="el-icon-plus">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="bankPay">确认</el-button>
            <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
          </el-form-item>
        </template>
      </el-form>
    </el-dialog>
    <!--    支付历史-->
    <el-drawer
        size="700px" class="payHis"
        :visible.sync="payDia"
        title="支付历史" :with-header="false">
      <div class="flex title">
        <h4>支付历史 </h4>
        <span>共计支付金额：{{ totalFee | filterMoney }}元</span>
        <i class="el-icon-close" @click="payDia=false"></i>
      </div>
      <el-table
          border
          :data="tableHistory"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 160px)">
        <el-table-column label="充值时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.payTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值金额(元)" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.payFee | filterMoney }}
          </template>
        </el-table-column>
        <el-table-column label="充值方式">
          <template slot-scope="scope">
            <span :class=" scope.row.payType == 'bank' ? 'colorStyle' : 'wxPay'">
              {{ scope.row.payType == 'bank' ? '银行转账' : '微信支付' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="充值凭证" class-name="colorStyle" min-width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.payVoucher&&scope.row.payVoucher.length>0">
              <img v-for="item in scope.row.payVoucher" :src="item" alt="" height="50px">
            </template>
          </template>
        </el-table-column>
        <el-table-column label="发票凭证" width="140" show-overflow-tooltip>
          <template slot-scope="scope">
            <template v-if="scope.row.invoice&&scope.row.invoice.length>0">
              <img v-for="item in scope.row.invoice" :src="item" alt="" height="50px">
            </template>
            <span class="wxPay" v-else>
              等待上传
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChangeHis"
            @current-change="skipPageHis"
            :current-page="pageHis.current"
            :page-size="pageHis.size"
            layout="prev, pager, next"
            :total="pageHis.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(pageHis.total / pageHis.size) }}页</span>
      </div>
    </el-drawer>
    <!--    余额不足-->
    <el-dialog
        class="commonDialog flex" center
        top="0"
        :visible.sync="balanceDia"
        width="260px">
      <img src="../../assets/img/tips.png" alt="" width="52px">
      <h2>现金余额不足，仅剩余{{ balanceDay }}天，请及时充值</h2>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="topUp">去充值</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>

export default {
  name: "pay",
  data() {
    return {
      search: '',
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      tableData: [],
      companyInfo: {},
      // 详情
      drawer: false,
      cageDetail: {},
      activeCageSum: null,
      endCageNum: null,
      // 充值金额
      dialogFormVisible: false,
      formAction: {
        orderFee: '',
        payType: '',
        remark: '',
        transferVoucher: ''
      },
      disabled: false,
      boxShow: false,
      qrcode: '',
      rules: {
        orderFee: [
          {required: true, message: '支付金额不能为空', trigger: 'blur'}
        ],
      },
      // 支付历史
      payDia: false,
      tableHistory: [],
      pageHis: {
        current: 1,
        size: 10,
        total: 10,
      },
      totalFee: null,
      queryTimer: 0,
      timerInterval: null,
      balanceDia: false,
      balanceDay: null,
      // 转账
      upLoadUrl: this.$local.baseURL() + 'files/file',
      fileList: [],//文件
      headers: {
        "Authentication": this.$store.state.account.token
      }
    }
  },
  mounted() {
    this.getData()
    this.getCompanyInfo()
    this.getBalance()
  },
  filters: {
    filterMoney(num) {
      return (num / 100).toFixed(2)
    }
  },
  destroyed() {
    window.clearInterval(this.queryTimer);
  },
  inject: ['reload'],
  methods: {
    // 获取使用笼舍
    getData() {
      this.$postJson('/subject/info/page',
          {
            size: this.page.size,
            page: this.page.current,
            key: this.search
          }).then(res => {
        if (res.data.success) {
          this.tableData = res.data.data.records;
          this.page.current = res.data.data.current;
          this.page.total = res.data.data.total;
          this.page.size = res.data.data.size;
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    // 获取企业信息
    getCompanyInfo() {
      this.$postJson('/oauth/ac-company/account/info').then(res => {
        if (res.data.success) {
          this.companyInfo = res.data.data
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 打开详情
    openDetail(id) {
      this.drawer = true
      this.$postJson('/subject/info/' + id).then(res => {
        if (res.data.success) {
          // 使用中笼舍总计时间
          let sum = 0
          res.data.data.activeCages.forEach(item => {
            sum += item.duration
          })
          this.activeCageSum = sum
          // 已完成笼舍总计时间
          let endSum = 0;
          res.data.data.endCages.forEach(item => {
            endSum += item.duration
          })
          this.endCageNum = endSum
          this.cageDetail = res.data.data
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 去充值
    topUp() {
      if (this.balanceDia) {
        this.balanceDia = false
      }
      this.dialogFormVisible = true
      this.formAction = {}
      this.qrcode = ''
      this.disabled = false
    },
    // 充值历史
    payDiaBtn() {
      this.payDia = true
      this.getHis()
    },
    getHis() {
      this.$postJson('/order/pay-order/page',
          {
            size: this.pageHis.size,
            page: this.pageHis.current,
          }).then(res => {
        if (res.data.success) {
          // 时间处理
          res.data.data.page.records.forEach(item => {
            if (item.payTime) {
              let a = item.payTime
              item.payTime = a.substring(0, 4) + '-' + a.substring(4, 6) + '-' + a.substring(6, 8)
            } else {
              item.payTime = '-'
            }
            if (item.payVoucher) {
              item.payVoucher = item.payVoucher.split(",")
            }
            if (item.invoice) {
              item.invoice = item.invoice.split(",")
            }
          })
          this.tableHistory = res.data.data.page.records;
          this.pageHis.current = res.data.data.page.current;
          this.pageHis.total = res.data.data.page.total;
          this.pageHis.size = res.data.data.page.size;
          this.totalFee = res.data.data.totalFee
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 每页多少条
    handleSizeChangeHis(val) {
      this.pageHis.size = val;
    },
    // 当前页
    skipPageHis(current) {
      this.pageHis.current = current;
      this.getHis();
    },
    changeType(val) {
      this.disabled = true
      if (val == 'wxchat') {
        this.boxShow = true
        this.btnZhiFuClick()
      }
    },
    // 微信支付
    btnZhiFuClick() {
      let self = this
      let params = {
        orderFee: this.formAction.orderFee * 100,
        payType: this.formAction.payType,
        remark: this.formAction.remark
      }
      self.$postJson('/wx/pay/native', params).then(res => {
        if (res.data.success) {
          // 二维码
          this.qrcode = res.data.data.qrcode
          // 查看订单情况
          let orderId = res.data.data.payOrder.id
          self.queryTimer = setInterval(() => {
            self.$postJson('/wx/pay/native', {id: orderId}).then(res => {
              if (res.data.success) {
                if (res.data.data.code == 203) { //已支付
                  this.$message.success("支付成功")
                  self.dialogFormVisible = false;
                  clearInterval(self.queryTimer);
                  clearInterval(self.timerInterval);
                }
              } else {
                this.$message.error(res.data.error)
                clearInterval(self.queryTimer);
                setTimeout('location.reload()', 3000);
              }
            })
          }, 3000);

        } else {
          self.$message.error(res.data.message);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取剩余天数
    getBalance() {
      this.$postJson('/oauth/ac-company/account/alarm').then(res => {
        if (res.data.success) {
          if (res.data.data <= 3) {
            this.balanceDay = res.data.data
            this.balanceDia = true
          } else if (res.data.data == null) {
            this.$message("当前没有使用中的笼舍")
          } else {
            this.balanceDia = false
          }
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 银行转账
    bankPay() {
      let self = this
      let name = this.fileList.map(o => o.url)
      this.formAction.transferVoucher = name.join(",");

      let params = {
        orderFee: this.formAction.orderFee * 100,
        transferVoucher: this.formAction.transferVoucher,
        remark: this.formAction.remark
      }
      self.$postJson('/order/pay-order/save', params).then(res => {
        if (res.data.success) {
          this.$message.success("支付成功")
          self.dialogFormVisible = false;
        } else {
          self.$message.error(res.data.message);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 上传转账凭证
    beforeAvatarUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
    },
    // 删除
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAvatarSuccess(response, file) {
      let obj = {
        name: response.name,
        url: response.url
      };
      this.fileList.push(obj);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/pay";
</style>
